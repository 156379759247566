import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';
import { breakpoints, theme } from '../styles/theme';

const Container = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  padding: 2rem;

  justify-content: center;
  align-items: center;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: -25%;
    left: -25%;

    filter: blur(2rem);
    width: 150%;
    height: 150%;
    background-image: url(${({ image }) => image});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
  }


  > img {
    max-width: 100%;
    max-height: 100%;

    position: relative;
    z-index: 1;
    box-shadow: ${theme.shadow};
  }

  > div {
    z-index: 2;
    cursor: pointer;
    position: absolute;
  
    @media only screen and (min-width: ${breakpoints.desktop}) {
      position: static;
      padding: 1.5rem;
    }

    font-size: 2rem;
    color: white;
    filter: drop-shadow(0 0 1rem black);
  }
`;

const Left = styled.div`
  left: 0.5rem;
`;

const Right = styled.div`
  right: 0.5rem;
`;

const Gallery = ({ images }) => {
  const [selected, setSelected] = useState(0);

  const previous = () => {
    if (selected === 0) {
      setSelected(images.length - 1);
      return;
    }

    setSelected(selected - 1);
  }

  const next = () => setSelected((selected + 1) % images.length)

  return (
    <Container image={images[selected]}>
      <Left onClick={previous}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </Left>
      <img src={images[selected]} alt="Gallery" />
      <Right onClick={next}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Right>
    </Container>
  );
};

export default Gallery;
