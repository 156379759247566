import * as chroma from 'chroma-js';

export const colors = {
  offBlack: '#030303',
  offWhite: '#fefefe',

  lightGrey: '#f0f0f0',
  grey: '#555',

  sanMarino: '#4869B9',
};

export const theme = {
  mainBg: colors.offWhite,
  text: colors.offBlack,

  primary: colors.sanMarino,
  primaryLight: chroma(colors.sanMarino).brighten(1),
  primaryLighter: chroma(colors.sanMarino).brighten(2),

  shadow: `0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06)`,

  facebook: '#3078F2',
  instagram: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);'
};

export const breakpoints = {
  desktop: `${1024}px`,
};

export const fontFamilies = {
  body: "'Open Sans', sans-serif;",
  titles: "'Proza Libre', sans-serif;",
};
