import React from 'react';
import styled from 'styled-components';
import { Container as DefaultContainer } from '../components/Container';
import Service from '../components/Service';
import { Subtitle, Title } from '../components/Titles';
import { services } from '../data/services.json';
import { breakpoints } from '../styles/theme';

const Container = styled(DefaultContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 0.25rem;
  padding: 2rem;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    grid-template-columns: 1fr;
  }
`;

const Services = () => {
  return (
    <Container>
      <Subtitle>
        Offriamo un'ampia gamma di servizi per soddisfare ogni esigenza
      </Subtitle>
      <Title>I nostri servizi</Title>

      <Row>
        {services.map((service) => (
          <Service key={service.name} {...service} />
        ))}
      </Row>
    </Container>
  );
};

export default Services;
