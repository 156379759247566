import React from 'react';
import styled from 'styled-components';
import { Container } from '../components/Container';
import { Title } from '../components/Titles';
import { breakpoints, colors } from '../styles/theme';
import { partner } from '../data/data.json';

const Wrapper = styled.section`
  background: ${colors.lightGrey};
  padding: 3rem 2rem;
`;

const List = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Item = styled.div`
  display: flex;

  align-items: center;

  > img {
    max-width: 100%;
    filter: grayscale(100%);
    opacity: 0.5;
  }
`;

const Center = styled.div`
  text-align: center;
`;

const Partner = () => {
  return (
    <Wrapper>
      <Container>
        <Center>
          <Title>Convenzioni</Title>
        </Center>
        <List>
          {partner.map((picture, index) => (
            <Item key={`partner-${index}`}>
              <img src={picture} alt="Partner" />
            </Item>
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};

export default Partner;
