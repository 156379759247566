import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg';
import Bar from '../components/Bar';
import { Container as DefaultContainer } from '../components/Container';
import Gallery from '../components/Gallery';
import { gallery } from '../data/data.json';

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;

  position: relative;

  & + * {
    margin-top: 1rem;
  }
`;

const BarContainer = styled(DefaultContainer)`
  position: relative;
  z-index: 0;

  margin-top: -3rem;
  padding-bottom: 3rem;

`;

const GalleryContainer = styled.div`
  position: relative;
  z-index: 0;

  height: calc(75vh - 4rem);
`;

const LogoItem = styled.img`
  width: 20rem;
  max-width: 100%;
  position: relative;
  z-index: 1;

  color: white;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  z-index: 1;

  padding: 2rem;

  &:after {
    position: absolute;
    top: -18rem;
    left: -15rem;
    z-index: 0;
    content: '';
    width: 15rem;
    height: 0;
    box-shadow: 0 0 100vh 28rem black;
    opacity: .55;
  }
`;

const Logo = () => {
  return (
    <Wrapper>
      <LogoContainer>
        <LogoItem src={logo} alt="Carrozzeria Aurora" />
      </LogoContainer>

      <GalleryContainer>
        <Gallery images={gallery} />
      </GalleryContainer>
      <BarContainer>
        <Bar />
      </BarContainer>
    </Wrapper>
  );
};

export default Logo;
