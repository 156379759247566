import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { fontFamilies, theme } from './theme';

const GlobalStyle = createGlobalStyle`
    ${reset}

    html, body, #root {
        height: 100%;
    }

    body {
        background: ${theme.mainBg};
        color: ${theme.text};

        font-family: ${fontFamilies.body};
    }

    *, *:before, *:after {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: ${theme.primary};
    }
`;

export default GlobalStyle;
