import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faMapPin,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors, theme } from '../styles/theme';
import { address, phone, mail, instagram, facebook } from '../data/data.json';

const Container = styled.section`
  display: flex;
  border-radius: 0.75rem;
  box-shadow: ${theme.shadow};

  background: ${colors.offWhite};

  background: linear-gradient(
    90deg,
    rgba(253, 253, 253, 1) 66%,
    rgba(253, 253, 253, 0.6) 100%
  );

  @media only screen and (max-width: ${breakpoints.desktop}) {
    flex-direction: column;
  }
`;

const Position = styled.span`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 0.5rem;
  }

  > svg {
    color: ${theme.primary};
  }
`;

const Social = styled.div`
  display: flex;
  font-size: 1.5rem;

  > * + * {
    margin-left: 1rem;
  }

  > * {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    padding: 1.2rem;

    align-items: center;
    justify-content: center;
  }
`;

const Info = styled.div`
  padding: 0 1rem;
  flex: 1;

  display: flex;
  align-items: center;

  > * + * {
    margin-left: 1rem;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    flex-direction: column;

    > * + * {
      margin-left: 0;
    }

    > * {
      margin-top: 1rem;
    }

    > *:last-child {
      margin-bottom: 1rem;
    }
  }
`;

const Clickable = styled.span`
  background: linear-gradient(
    -45deg,
    ${theme.primary} 0%,
    ${theme.primaryLight} 100%
  );

  color: ${colors.offWhite};
  border-radius: 0.5rem;
  box-shadow: ${theme.shadow};

  transition: 250ms ease-in-out opacity;

  &:hover {
    opacity: 0.8;
  }
  
  &.facebook {
    background: ${theme.facebook}
  }

  &.instagram {
    background: ${theme.instagram}
  }
`;

const Phone = styled(Clickable)`
  padding: 1.25rem;
  margin: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  > * + * {
    margin-left: 1rem;
  }
`;

const Bar = () => {
  return (
    <Container>
      <Info>
        <Position>
          <FontAwesomeIcon icon={faMapPin} />
          <span>{address}</span>
        </Position>
        <Social>
          <Clickable as="a" href={`mailto:${mail}`}>
            <FontAwesomeIcon icon={faEnvelope} />
          </Clickable>

          <Clickable as="a" href={instagram} className="instagram">
            <FontAwesomeIcon icon={faInstagram} />
          </Clickable>

          <Clickable as="a" href={facebook} className="facebook">
            <FontAwesomeIcon icon={faFacebookF} />
          </Clickable>
        </Social>
      </Info>
      <a href={`tel:${phone}`}>
        <Phone>
          <FontAwesomeIcon icon={faPhoneAlt} />
          <span>{phone}</span>
        </Phone>
      </a>
    </Container>
  );
};

export default Bar;
