import React from 'react';
import Map from './components/Map';
import Footer from './sections/Footer';
import Logo from './sections/Logo';
import Partner from './sections/Partner';
import Services from './sections/Services';

const Main = () => {
  return (
    <>
      <Logo />
      <Services />
      <Partner />
      <Map />
      <Footer />
    </>
  );
};

export default Main;
