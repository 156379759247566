import styled from 'styled-components';

import { colors, fontFamilies } from '../styles/theme';

export const Title = styled.h1`
  text-align: center;
  font-family: ${fontFamilies.titles};
  font-size: 2rem;
  font-weight: bold;

  margin: 1rem 0;
`;

export const Subtitle = styled.h2`
  text-align: center;
  color: ${colors.grey};
  font-weight: 500;
  font-size: 1.12rem;
`;
