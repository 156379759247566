import React from 'react';
import styled from 'styled-components';
import { Container as DefaultContainer } from '../components/Container';
import { partitaIva, phone } from '../data/data.json';

const Container = styled(DefaultContainer)`
  padding: 2rem 1rem;
`;

const Footer = () => {
  return (
    <Container as="footer">
      Carrozzeria Aurora, <a href={`tel:${phone}`}>{phone}</a> - P. IVA{' '}
      {partitaIva}
    </Container>
  );
};

export default Footer;
