import React from 'react';
import styled from 'styled-components';
import { colors, fontFamilies, theme } from '../styles/theme';

const Container = styled.article`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

const Square = styled.div`
  padding: 0.75rem;
  margin-bottom: 1rem;

  border-radius: 1.5rem;
  box-shadow: ${theme.shadow};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  border: solid 0.25rem transparent;
  background: ${theme.mainBg};
  background-clip: padding-box;
  color: ${colors.grey};

  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -0.25rem;

    border-radius: inherit;
    background: linear-gradient(
      -45deg,
      ${theme.primary} 0%,
      ${theme.primaryLight} 100%
    );
  }
`;

const Icon = styled.div`
  background: linear-gradient(
    -45deg,
    ${theme.primary} 0%,
    ${theme.primaryLight} 100%
  );

  width: 5rem;
  height: 5rem;

  mask-image: url(${({ icon }) => icon});
`;

const Title = styled.h1`
  font-family: ${fontFamilies.titles};
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;

  margin: 0.5rem 0;
`;

const Description = styled.p`
  line-height: 1.4;
  max-width: 30rem;
  text-align: center;
  color: ${colors.grey};

  white-space: pre-wrap;
`;

const Service = ({ icon, name, description }) => {
  return (
    <Container>
      <Square>
        <Icon icon={icon} />
      </Square>
      <Title>{name}</Title>
      {description && <Description>{description}</Description>}
    </Container>
  );
};

export default Service;
