import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { MapContainer as LeafletMap, Marker, TileLayer } from 'react-leaflet';
import styled from 'styled-components';
import { Container as DefaultContainer } from './Container';
import { Subtitle, Title } from './Titles';
import { address } from '../data/data.json';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

const Container = styled(DefaultContainer)`
  text-align: center;
  margin: 2rem auto;
`;

const Titles = styled.div`
  margin-bottom: 2rem;
`;

const MapContainer = styled.div`
  height: 30rem;
  width: 100%;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.15), 0 1px 10px rgba(0, 0, 0, 0.25);

  > * {
    height: 100%;
  }

  position: relative;
  z-index: 1;

  border-radius: 3srem;
  overflow: hidden;

  border-radius: 2rem;
`;

const Map = () => {
  const position = [44.3705284, 11.7161239];
  const zoom = 15;

  if (typeof window === 'undefined') return null;

  return (
    <Container>
      <Titles>
        <Subtitle>Ci trovi in</Subtitle>
        <Title>{address}</Title>
      </Titles>
      <MapContainer>
        <LeafletMap center={position} zoom={zoom} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            maxZoom={19}
          />
          <Marker position={position} />
        </LeafletMap>
      </MapContainer>
    </Container>
  );
};

export default Map;
